.loginWrapper {
  background-image: url("../../assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: #1a293d;
  width: 100%;
  height: 100%;
  .content {
    min-width: 1280px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    .loginHeader {
      position: absolute;
      top: 60px;
      left: 50px;
      .headerTitle {
        height: 23px;
        font-size: 24px;
        font-weight: 400;
        color: #fff;
        line-height: 20px;
      }
      .headerSubTitle {
        margin-top: 12px;
        font-size: 12px;
        font-weight: 200;
        color: rgba(199, 199, 199, 1);
        line-height: 22px;
      }
    }
    .display {
      width: 100%;
      height: 100%;
      margin: 13% 10% 8% 7%;
      .displayVideo {
        width: 100%;
        height: 100%;
      }
      .displayImage {
        width: 100%;
        height: 100%;
        background: url(../../assets/images/dshx.png) 0 center no-repeat;
        background-size: contain;
      }
    }
    .cardForm {
      width: 400px;
      min-height: 440px;
      background-color: rgba(11, 48, 73, 0.8);
      padding: 30px;
      margin-right: 150px;
      border-radius: 8px;
      box-shadow: 0px 0px 45px 4px rgba(19, 26, 53, 0.43);
      .cardFormTitle {
        text-align: center;
        .mainTitle {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba(246, 246, 246, 1);
          line-height: 22px
        }
        .subTitle {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba(246, 246, 246, 1);
          margin-top: 20px;
          line-height: 22px;
        }
      }
      .carFormBody {
        :global {
          input {
            box-shadow: inset 0 0 0 1000px #252A3A !important;
          }
          input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus {
            border: none !important;
          }
          .ant-input {
            border-radius: 0 !important;
            height: 30px;
            font-size: 16px;
            color: #fff !important;
          }
          .ant-input-affix-wrapper {
            background-color: #252A3A !important;
          }
          .formPass {
            .ant-form-item:nth-child(2) {
              margin-bottom: 0px !important;
            }
          }
        }
        .checkboxWrapper {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          margin-bottom: 20px;
        }
        .button {
          width: 100%;
        }
      }
    }
  }
}

.tag {
  width: 100%;
  color: #721c24;
  font-size: 15px;
  font-weight: 400;
  padding: 0.75rem 1.25rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  :global {
    .ant-tag-close-icon {
      color: #721c24;
    }
  }
}

.bottomText{
  position: absolute;
  bottom: 3%;
  text-align: center;
  width: 100%;
  margin: 0;
  a,a:link,a:visited,a:hover,a:active{
    text-decoration: none;
    color:inherit;
  }
}

/* 屏幕小于1000px */
@media screen and (max-width: 1000px) {
  .loginHeader {
    display: none;
  }

  .loginWrapper {
    background-image: none;
  }

  .content {
    min-width: 100% !important;
  }

  #loginForm {
    position: absolute;
    width: 100%;
  }

  .display {
    margin: 0 !important;
    background-color: #021622;
  }

  .cardForm {
    width: 100% !important;
    margin: 0 !important;
    height: 100vh !important;
    min-height: 100% !important;
    padding-top: 80px !important;
    background-color: rgba(2, 22, 34, 0.8) !important;
    border-radius: 0 !important;
  }
}