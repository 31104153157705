@import "~@maxtropy/antd-theme/antd-dark.css";
@import "~@maxtropy/central-commons-ui/dist/style/themes/dark/color";

html {
  background-color: $body-background;
  color: $text-color;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: 'Microsoft YaHei', 'PingFang SC', '黑体', '宋体', sans-serif;
}

#root {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: 1px solid $border-color-base;
  -webkit-text-fill-color: $text-color;
  caret-color: $text-color;
  box-shadow: inherit;
  transition: background-color 7200s ease-in-out 0s;
}